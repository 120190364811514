import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import MySvg from '../../assets/time-management-cuate.svg';
import Logot6d from '../../assets/logo-t6d.png';
import instagramIcon from '../../assets/instagram.png';
import whatsIcon from '../../assets/whatsapp.png';
import { useNavigate } from 'react-router-dom';

export default function AgendaSucesso() {
  const navigate = useNavigate(); // Mover o useNavigate para dentro do componente

  return (
    <Box
      sx={{
        backgroundColor: '#ffd004',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        flexDirection: 'column',
      }}
    >

      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        flexDirection: 'column',
        backgroundColor: 'white',
        margin: 3,
        paddingLeft: 2,
        paddingRight: 2,
        paddingBottom: 3,
        borderRadius: 5
      }}>
        <Box sx={{
          width: {
            sm: '300px',
            xs: '250px'
          },
          height: 'auto'
        }}>
          <img src={MySvg}></img>
        </Box>

        <Typography
          variant="h5"
          color={'#373737'}
          sx={{
            textAlign: 'center',
            fontWeight: 'bold'
          }}>
          Agendamento realizado com sucesso!
        </Typography>


        <Typography
          variant="h4"
          color={'grey'}
          sx={{
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: 12
          }}>
          Obrigado por agendar conosco!
        </Typography>

        <Button
          variant="contained"
          color="primary"
          sx={{
            marginTop: 2,
            borderRadius: 5,
            backgroundColor: '#373737',
            '&:hover': {
              backgroundColor: 'black', // Mantém o preto ao passar o mouse
            },

          }}
          onClick={() => navigate('/')}
        >
          Voltar à Página Inicial
        </Button>

        <Box sx={{
              marginTop: 5,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignContent: 'center',
              justifyItems: 'center',
              alignItems: 'center',
            }}>
              <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                justifyItems: 'center',
                alignItems: 'center',
                gap: 2
              }}>
                <Box>
                  <Typography
                    variant="subtitle1"
                    color={'grey'}
                    sx={{
                      fontSize: 16,
                      fontWeight: 'bold'
                    }}>
                    Siga-nos
                  </Typography>
                </Box>
                <Box>
                  <a href="https://www.instagram.com/redesuperbarber" target="_blank" rel="noopener noreferrer">
                    <img src={instagramIcon} style={{ width: '30px', height: "auto" }}></img>
                  </a>
                </Box>
                <Box>
                  <a href="https://wa.me/5548996684859" target="_blank" rel="noopener noreferrer">
                    <img src={whatsIcon} style={{ width: '30px', height: "auto" }}></img>
                  </a>
                </Box>
              </Box>
            </Box>

      </Box>

      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
        justifyItems: 'center',
      }}>
        <Typography
          variant="body2"
          component="div"
          color={'white'}
          sx={{
            textAlign: 'center',
            fontSize: 15,
            marginRight: 0.5
          }}>
          Desenvolvido por <a href="https://t6d.com.br" target="_blank"></a>
        </Typography>
        <img src={Logot6d} alt="Logo T6D" width={40} height={20} />
      </Box>
    </Box>
  );
}
