import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, } from 'react-router-dom';
import Home from "./pages/home/index.tsx";
import AgendaSucesso from './pages/agendaSucesso/index.tsx';
import TagManager from 'react-gtm-module';

function App() {
  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-MW5VHLB3' });
  }, []);
  
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path="/agenda-sucesso" element={<AgendaSucesso />} />
      </Routes>
    </Router>
  );
}

export default App;
