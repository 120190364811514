import { Button, MenuItem, Select, Stepper, TextField, Box, Typography, Step, StepLabel, Backdrop, CircularProgress } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Alert from '@mui/material/Alert';
import React, { useEffect, useState } from 'react';
import api from "../../service";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import InputMask from 'react-input-mask';
import superbarber from '../../assets/superbarber.jpeg';
import instagramIcon from '../../assets/instagram.png';
import whatsIcon from '../../assets/whatsapp.png';
import '@fortawesome/fontawesome-free/css/all.min.css';

import 'dayjs/locale/pt-br';
import dayjs from 'dayjs';


export default function Home() {
  interface Empresa {
    nome: string;
    cnpj: string;
    id: string;
  }

  interface Usuario {
    id: string;
    nome: string;
    usuario_nome: string;
  }

  interface Servico {
    id: string;
    tempo: number;
    descricao: string;
  }

  interface Agenda {
    Hora: string;
    agendado: boolean;
    indisponivel: boolean;
  }

  const [barbearia, setEmpresa] = useState<Empresa[]>([]);
  const [barbeariaSelecionada, setEmpresaSelecionada] = useState("0");
  const [usuarioEmpresa, setUsuarioEmpresa] = useState<Usuario[]>([]);
  const [usuarioSelecionado, setUsuarioSelecionada] = useState("0");
  const [servico, setServico] = useState<Servico[]>([]);
  const [servicoSelecionado, setServicoSelecionada] = useState("0");
  const [dataSelecionada, setDataSelecionada] = React.useState("0");
  const [agenda, setAgenda] = React.useState<Agenda[]>([]);
  const [loadingHorarios, setloadingHorarios] = useState(false);
  const [intervalo, setIntervalo] = React.useState(0);
  const [formCadastroValido, setFormCadastroValido] = useState<boolean | null>(false);
  const navigate = useNavigate();

  const scrollToBottom = (): void => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth', // Faz o scroll suave
    });
  };

  const [etapaAtual, setEtapaAtual] = useState(0);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors }
  } = useForm(
    {
      defaultValues: {
        local: null,
        item: null,
        usuario: null,
        dataInicio: '',
        dataFim: '',
        cliente: null,
        telefone: null,
        email: null,
      },
    });

  const nomeValue = watch('cliente');
  const telefoneValue = watch('telefone');

  useEffect(() => {
    if (barbeariaSelecionada !== '0') {
      async function retornaUsuario() {
        let response = await api.get(`usuario/${barbeariaSelecionada}/colaboradores/agenda`);
        setUsuarioEmpresa(response.data);
      }
      retornaUsuario();
    }

  }, [barbeariaSelecionada]);

  useEffect(() => {
    async function retornaFilial() {
      const filialResponse = await api.get(`empresa/filiais/agenda`);
      setEmpresa(filialResponse.data);
    }
    retornaFilial();

  }, [])

  useEffect(() => {
    if (barbeariaSelecionada !== '0') {
      async function retornaServico() {
        const response = await api.get(`/item/agenda/agendamento/${barbeariaSelecionada}`);
        setServico(response.data);
      }
      retornaServico();
    }

  }, [barbeariaSelecionada]);

  useEffect(() => {
    if (etapaAtual == 0) {
      setAgenda([])
    }
  }, [etapaAtual]);

  useEffect(() => {
    if (etapaAtual == 1) {
      scrollToBottom();
    }
  }, [etapaAtual]);

  function obterNumerosDaString(str) {
    if (!str) return;
    return str.replace(/\D/g, '');  
  }

  useEffect(() => {
    let apenasDigito = String(obterNumerosDaString(telefoneValue));
    setFormCadastroValido(nomeValue && (apenasDigito && (String(apenasDigito).length == 10 || String(apenasDigito).length == 11)))
  }, [nomeValue, telefoneValue]);

  async function buscaAgenda(id, data) {
    setloadingHorarios(true)
    api.post(`/agendamento/agenda/cliente/${id}`, { data: data }).then(response => {
      setAgenda(response.data.horarioTrabalho)
      setIntervalo(response.data.intervalo)
      setTimeout(() => {
        scrollToBottom();
      }, 500);
      setloadingHorarios(false);
    });
  }

  const isNextButtonDisabled = barbeariaSelecionada === '0' || usuarioSelecionado === '0' || servicoSelecionado === '0';


  const StepSelect = () => {
    return (
      <Box sx={{
        padding: 2,
        display: "flex",
        flexDirection: 'column',
        rowGap: 0.5
      }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label" sx={{
            color: 'black',
            '&.Mui-focused': {
              color: 'black',
            },
          }}>Barbearia</InputLabel>
          <Select
            required
            fullWidth
            size='small'
            notched
            label="Barbearia"
            id="Barbearia"
            value={barbeariaSelecionada}
            {...register('local')}
            onChange={(e) => {
              setEmpresaSelecionada(e.target.value);
              setUsuarioSelecionada('0');
              setServicoSelecionada('0');
            }}
            sx={{
              borderRadius: 5,
              marginBottom: 1,
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'black',
              },

            }}
          >
            <MenuItem value={'0'}>Selecione uma barbearia</MenuItem>
            {barbearia.map((empresa, index) => (
              <MenuItem value={empresa.id} key={index}>
                {empresa.nome}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label" sx={{
            color: 'black',
            '&.Mui-focused': {
              color: 'black',
            },
          }}>Serviço</InputLabel>
          <Select
            required
            fullWidth
            size='small'
            notched
            label="Serviço"
            id="Serviço"
            value={servicoSelecionado}
            {...register('item')}
            onChange={(e) => {
              setServicoSelecionada(e.target.value);
            }}
            sx={{
              borderRadius: 5,
              marginBottom: 1,
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'black',
              },
            }}
          >
            <MenuItem value={'0'}>Selecione um serviço</MenuItem>
            {servico.map((servico, index) => (
              <MenuItem value={servico.id} key={index}>
                {servico.descricao}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label" sx={{
            color: 'black',
            '&.Mui-focused': {
              color: 'black',
            },
          }}>Barbeiro</InputLabel>
          <Select
            required
            fullWidth
            size='small'
            label="Barbeiro"
            notched
            id="Barbeiro"
            value={usuarioSelecionado}
            {...register('usuario')}
            onChange={(e) => {
              setUsuarioSelecionada(e.target.value);
            }}
            sx={{
              borderRadius: 5,
              marginBottom: 2,
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'black',
              },
            }}
          >
            <MenuItem value={'0'}>Selecione um barbeiro</MenuItem>
            {usuarioEmpresa.map((usuario, index) => (
              <MenuItem value={usuario.id} key={index}>
                {usuario.usuario_nome}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          fullWidth
          size="small"
          variant="contained"
          disabled={isNextButtonDisabled}
          onClick={() => {
            setEtapaAtual((prev) => prev + 1);
          }}
          sx={{
            height: 40,
            borderRadius: 5,
            color: 'white',
            marginBottom: 2,
            backgroundColor: '#373737',
            '&:hover': {
              backgroundColor: 'black', // Mantém o preto ao passar o mouse
            },
          }}
        >
          PRÓXIMO
        </Button>
      </Box>
    );
  };

  const [selectedHora, setSelectedHora] = useState('');

  const StepAgenda = () => {
    dayjs.locale('pt-br');
    return (
      <Box
        sx={{
          padding: 2,
          display: "flex",
          flexDirection: 'column',
          rowGap: 0,
          overflow: 'auto'
        }}>

        <Backdrop
          sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
          open={loadingHorarios}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale="pt-br"
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginBottom={-5}
          >
            <DateCalendar
              disablePast={true}
              minDate={dayjs().subtract(1, 'day')}
              maxDate={dayjs().add(6, 'months')}
              onChange={(newValue) => {
                setDataSelecionada(newValue.$d);
                if (selectedHora) {
                  setSelectedHora('');
                }
                buscaAgenda(usuarioSelecionado, newValue.$d);
              }}
              sx={{
                '& .MuiPickersDay-root': {
                  '&.Mui-selected': {
                    backgroundColor: '#373737', // Fundo preto para o dia selecionado
                    color: 'white', // Texto branco para o dia selecionado
                    '&:hover': {
                      backgroundColor: '#373737', // Mantém o preto ao passar o mouse sobre o dia selecionado
                    },
                  },
                  '&:hover': {
                    backgroundColor: 'black', // Fundo preto ao passar o mouse sobre um dia não selecionado
                    color: 'white', // Texto branco ao passar o mouse
                  },
                  '&.Mui-disabled': {
                    color: '#c8c8c8', // Cor cinza para dias desabilitados
                  },
                },
                '& .MuiPickersDay-dayWithMargin': {
                  '&.Mui-selected': {
                    backgroundColor: 'black', // Fundo preto para o dia selecionado
                    color: 'white', // Texto branco para o dia selecionado
                  },
                },
              }}
            />
          </Box>
          <div>
            <Typography color={'textSecondary'} sx={{
              textAlign: 'center',
              fontWeight: 'bold',
              fontSize: 18
            }}>
              Horários disponíveis
            </Typography>
            {agenda.map((agenda, index) => {
              const isDisabled = agenda.agendado || agenda.indisponivel;
              const isSelected = selectedHora === agenda.Hora;
              return (
                <Button
                  key={index}
                  onClick={() => {
                    setSelectedHora(agenda.Hora);

                    const [hora, minuto] = agenda.Hora.split(':').map(Number);

                    const dataInicio = new Date(dataSelecionada);
                    dataInicio.setHours(hora, minuto, 0, 0);

                    const dataFim = new Date(dataSelecionada);
                    dataFim.setHours(hora, minuto, 0, 0);
                    dataFim.setMinutes(dataFim.getMinutes() + intervalo)

                    const dataInicioISO = dataInicio.toISOString();
                    const dataFimISO = dataFim.toISOString();

                    setValue('dataInicio', dataInicioISO);
                    setValue('dataFim', dataFimISO);
                  }}
                  disabled={isDisabled}
                  sx={{
                    border: '1px solid', // Borda padrão
                    borderRadius: 3,
                    borderColor: '#d7d7d7', // Cor da borda dependendo da seleção
                    color: isSelected ? 'white' : 'black', // Cor do texto
                    backgroundColor: isSelected ? '#373737' : '#ffffff', // Cor de fundo ao selecionar
                    margin: '1px',
                    width: '15%',
                    '&:hover': {
                      backgroundColor: isSelected ? '#373737' : 'white', // Muda a cor ao passar o mouse
                    },
                  }}
                >
                  {(agenda.Hora)}
                </Button>
              )
            })}
          </div>
        </LocalizationProvider>
        <div>
          <Button
            fullWidth
            size="small"
            variant="contained"
            disabled={!selectedHora}
            onClick={() => {
              setEtapaAtual(etapaAtual + 1);
            }}
            sx={{
              height: 40,
              color: "white",
              borderRadius: 5,
              marginTop: 2,
              backgroundColor: '#373737',
              '&:hover': {
                backgroundColor: 'black',
              },
            }}>
            PRÓXIMO
          </Button>
          <Button
            fullWidth
            size="small"
            variant="contained"
            onClick={() => {
              setEtapaAtual(etapaAtual - 1);
            }}
            sx={{
              height: 40,
              color: "white",
              borderRadius: 5,
              marginTop: 2,
              backgroundColor: '#5e5e60',
              '&:hover': {
                backgroundColor: 'black',
              },
            }}>
            VOLTAR
          </Button>
        </div>
      </Box>
    );
  };


  const StepCliente = () => {
    const [telefone, setTelefone] = useState('');
    return (
      <Box sx={{
        padding: 2,
        display: "flex",
        flexDirection: 'column',
        rowGap: 0
      }}>
        <TextField
          required
          fullWidth
          size='small'
          id="nome"
          label="Nome"
          inputProps={{ maxLength: 50 }}
          {...register('cliente', {
            required: 'Nome é obrigatório',
            maxLength: { value: 50, message: 'Máximo de 50 caracteres' },
          })}
          error={!!errors.cliente}
          helperText={errors.cliente ? errors.cliente.message : ''}
          sx={{
            marginTop: 1,
            marginBottom: 1,
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'black', // Cor da borda padrão
              },
              '&:hover fieldset': {
                borderColor: 'black', // Cor da borda ao passar o mouse
              },
              '&.Mui-focused fieldset': {
                borderColor: 'black', // Cor da borda ao focar
              },
              '& input': {
                color: 'black', // Cor do texto
              },
            },
            '& .MuiInputLabel-root': {
              color: 'black', // Cor do label padrão
              '&.Mui-focused': {
                color: 'black', // Cor do label ao focar
              },
            },
            '& .MuiFormHelperText-root': {
              color: 'black', // Cor do texto de ajuda
            },
          }}
        />
        <InputMask
          mask="(99) 999999999"
          value={telefone}
          onChange={(e) => {
            setTelefone(e.target.value);
            setValue('telefone', e.target.value);
          }}
          onBlur={(e) => setValue('telefone', e.target.value)}
        >
          {() => (
            <TextField
              required
              fullWidth
              size='small'
              id="telefone"
              label="Telefone"
              error={!!errors.telefone}
              helperText={errors.telefone ? errors.telefone.message : ''}
              sx={{
                marginTop: 1,
                marginBottom: 1,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'black', // Cor da borda padrão
                  },
                  '&:hover fieldset': {
                    borderColor: 'black', // Cor da borda ao passar o mouse
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'black', // Cor da borda ao focar
                  },
                  '& input': {
                    color: 'black', // Cor do texto
                  },
                },
                '& .MuiInputLabel-root': {
                  color: 'black', // Cor do label padrão
                  '&.Mui-focused': {
                    color: 'black', // Cor do label ao focar
                  },
                },
                '& .MuiFormHelperText-root': {
                  color: 'black', // Cor do texto de ajuda
                },
              }}
            />
          )}
        </InputMask>

        <Button
          id="btnRedefinir"
          variant="contained"
          type="submit"
          fullWidth
          disabled={!formCadastroValido}
          sx={{
            height: 40,
            color: 'white',
            borderRadius: 5,
            marginTop: 2,
            backgroundColor: '#ffd004',
            '&:hover': {
              backgroundColor: 'black',
            },
          }}
        >
          MARCAR HORÁRIO
        </Button>
        <Button
          fullWidth
          size="small"
          variant="contained"
          onClick={() => setEtapaAtual((prev) => prev - 1)}
          sx={{
            height: 40,
            color: 'white',
            marginTop: 2,
            borderRadius: 5,
            backgroundColor: '#5e5e60',
            '&:hover': {
              backgroundColor: 'black',
            },
          }}
        >
          VOLTAR
        </Button>
      </Box>
    );
  };

  const Etapas = [
    StepSelect(),
    StepAgenda(),
    StepCliente(),
  ];

  function desformata(item, tipo) {
    tipo === 'tel' ?
      item = item ? item.replace(/\D/g, "").trim() : null :
      item = item ? item.replace(/[.-]/g, "") : null
    return item
  }

  async function handleSave(data) {
    let telefone = desformata(data?.telefone, 'tel');
    data.telefone = telefone;

    const ajustarHoraUTC3 = (dataISO) => {
      const dataObj = new Date(dataISO);
      dataObj.setHours(dataObj.getHours() - 3);
      return dataObj.toISOString();
    };

    data.dataInicio = ajustarHoraUTC3(data.dataInicio);
    data.dataFim = ajustarHoraUTC3(data.dataFim);
    try {
      await api.post('agendamento', data)
        .then(() => {
          navigate('/agenda-sucesso');
        })
    } catch (error) {
      toast.error("Ocorreu um erro no agendamento, tente novamente.");
    }
  }

  const steps = ['Barbearia', 'Horário', 'Confirmar'];
  return (
    <Box
      sx={{
        backgroundColor: '#ffd004',
        maxHeight: '100vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          justifyItems: 'center',
          paddingTop: '2vh',
          paddingBottom: '50vh'
        }}>
        <Box
          component="img"
          src={superbarber}
          alt="Superbarber"
          sx={{
            width: '250px',
            height: 'auto',
            marginBottom: '30px' // Espaçamento extra entre a imagem e o texto
          }}
        />
        <Typography
          variant="h5"
          color={'white'}
          sx={{
            textAlign: 'center',
            fontWeight: 'bold'
          }}>
          Faça seu agendamento
        </Typography>
        {
          etapaAtual === 0 &&
          <Box sx={{
            padding: 2
          }}>
            <Alert severity="warning">
              Seu agendamento será confirmado automaticamente.
            </Alert>
          </Box>

        }
        <Box
          sx={{
            width: '100%',
            height: 'auto',
            bottom: 0,
            position: 'absolute',
            backgroundColor: '#ffffff',
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
          }}
        >
          <form onSubmit={handleSubmit(handleSave)}>
            <Stepper activeStep={etapaAtual} sx={{
              marginTop: 2,
              paddingLeft: 1,
              paddingRight: 1
            }}>
              {steps.map((label, index) => {
                return (
                  <Step key={label} sx={{
                    "& .MuiStepLabel-root .Mui-completed": {
                      color: "black"
                    },
                    "& .MuiStepLabel-root .Mui-active": {
                      color: "black"
                    },
                    "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                      fill: "white"
                    }
                  }}>
                    <StepLabel >{label}</StepLabel>
                  </Step>
                );
              })}

            </Stepper>
            {Etapas[etapaAtual]}
          </form>

          {
            etapaAtual === 0 &&
            <Box sx={{
              marginTop: -2,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignContent: 'center',
              justifyItems: 'center',
              alignItems: 'center',
            }}>
              <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                justifyItems: 'center',
                alignItems: 'center',
                gap: 2
              }}>
                <Box>
                  <Typography
                    variant="subtitle1"
                    color={'grey'}
                    sx={{
                      fontSize: 14
                    }}>
                    Siga-nos
                  </Typography>
                </Box>
                <Box>
                  <a href="https://www.instagram.com/redesuperbarber" target="_blank" rel="noopener noreferrer">
                    <img src={instagramIcon} style={{ width: '30px', height: "auto" }}></img>
                  </a>
                </Box>
                <Box>
                  <a href="https://wa.me/5548996684859" target="_blank" rel="noopener noreferrer">
                    <img src={whatsIcon} style={{ width: '30px', height: "auto" }}></img>
                  </a>
                </Box>
              </Box>
            </Box>

          }

        </Box>
      </Box>
    </Box>
  );
}
